import React, { useState, useEffect } from 'react';
import Timer from 'react-compound-timer';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import EditIcon from './assets/edit.png';
import './styles/box.css';

const Box = (props) => {
	const [started, setStarted] = useState(false);
	const [showEditIcon, setShowEditIcon] = useState(false);
	const [editing, setEditing] = useState(false);
	const [label, setLabel] = useState(props.label);
	const [bgColor, setBgColor] = useState(props.bgColor);

	useEffect(() => {
		if (props.editingSet) {
			setShowEditIcon(true);
		} else {
			setShowEditIcon(false);
		}
	}, [props]);

	const handleStart = () => {
		if (!started) {
			setStarted(true);
		}
	}

	const handleClose = () => {
		setEditing(false);
	}

	const editBox = e => {
		e.stopPropagation();

		setEditing(true);
	}

	const handleFieldChange = (e) => {
		setLabel(e.target.value);
	}

	const handleColorChange = (color) => {
		setBgColor(color);
	}

	const saveBox = () => {
		props.saveBox(props.id, label, bgColor);

		handleClose();
	}

	return (
		<>
			<div className='box' style={{ backgroundColor: props.bgColor }} onClick={handleStart}>
				<div className='label'>{props.label}</div>
				{showEditIcon ? <div className='icon' onClick={editBox}><img src={EditIcon} alt="Edit" /></div> : ""}
				{started ?
					<Timer
						initialTime={props.getTime()}
						direction="backward"
						formatValue={(value) => `${(value < 10 ? `0${value}` : value)}`}
					>
						{({ start, resume, pause, stop, reset, getTimerState, getTime }) => (
							<div className="timer" onDoubleClick={() => { reset();setStarted(false); }} onClick={() => { getTimerState() === 'PLAYING' ? pause() : (() => { reset(); start(); })() }}>
								<span className='hourmin'><Timer.Hours />:<Timer.Minutes /><span className='seconds'><Timer.Seconds /></span></span>
							</div>
						)}
					</Timer>
					: ""
				}
			</div>

			{editing ?
				<Dialog open={editing} onClose={handleClose} aria-labelledby="form-dialog-title">
					<DialogTitle id="form-dialog-title">Edit box</DialogTitle>
					<DialogContent>
						<TextField
							autoFocus
							margin="dense"
							id="label"
							label="Box label"
							value={label}
							type="text"
							fullWidth
							onChange={handleFieldChange}
							style={{ marginBottom: 30 }}
						/>
						<div>
							{props.colors.map(color => {
								return <div key={color} value={color} onClick={() => handleColorChange(color)} className='boxColorSelect' style={{ backgroundColor: color, border: (color === bgColor) ? 'solid 2px #000' : 'solid 2px #fff' }}>&nbsp;</div>
							})}
						</div>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleClose} color="primary">
							Cancel
						</Button>
						<Button onClick={saveBox} color="primary">
							Save
						</Button>
					</DialogActions>
				</Dialog>
				: ""}
		</>
	)
}

export default Box;